<template>
    <div class="share_container">
        <!-- 表格数据区域 -->
        <el-table style="width: 100%" border stripe :data="list"
            :header-cell-style="{ background: '#F2F6FC', color: '#606266' }">
            <el-table-column align="center" prop="headLogo" label="头像" width="200">
                <template slot-scope="scope">
                    <div class="permitImg-img" v-if="scope.row.headLogo">
                        <el-image style="width: 35%" :src="'http://file.innopinenut.com/' + scope.row.headLogo"
                            :preview-src-list="[
                                'http://file.innopinenut.com/' + scope.row.headLogo,
                            ]" />
                    </div>
                    <img style="width: 35%" v-else src="../../assets/head.png" alt="">
                </template>
            </el-table-column>
            <el-table-column align="center" prop="shareUserName" label="分享人名称" :show-overflow-tooltip="true" />

            <el-table-column label="分享类型" align="center">
                <template slot-scope="scope">
                    {{
                            scope.row.shareMethod == 1 ? '普通分享' : scope.row.shareMethod == 2 ? 'pdf胶囊按钮分享' :
                                scope.row.shareMethod == 3 ? 'pdf海报分享' : scope.row.shareMethod == 6 ? '资讯分享' : '资讯海报分享'
                    }}

                </template>
            </el-table-column>
            <el-table-column label="浏览用户类型" align="center">
                <template slot-scope="scope">
                    {{ scope.row.userRegister == 1 ? '新用户' : '老用户' }}
                </template>
            </el-table-column>
            <el-table-column align="center" prop="describe" label="描述" :show-overflow-tooltip="true" />
            <el-table-column align="center" prop="createTime" label="创建时间" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{ scope.row.createTime | getLocalTime }}
                </template>
            </el-table-column>

            <el-table-column align="center" label="操作" width="251px">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="beView(scope.row)">
                        查看分享被浏览记录
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- 分页 -->
        <pagination v-show="total > 0" :total="total" :page.sync="info.page" :limit.sync="info.pageSize"
            @pagination="getShareList" />
    </div>
</template>

<script>
import { getShareList } from '../../request/http'

export default {
    data() {
        return {
            info: {
                page: 1,
                pageSize: 10
            },
            list: [],
            total: 0
        }
    },
    created() {
        this.getShareList()
    },
    methods: {
        async getShareList() {
            const res = await getShareList(this.info)
            console.log(res);
            if (res.code !== 0) {
                return this.$message.error(res.code)
            }
            this.list = res.data
            this.total = res.count
        },
        beView(row) {
            console.log(row);
            this.$router.push({
                path: "beView",
                query: {
                    shareId: row.shareId,
                },
            });
        }
    }
}
</script>

<style scoped lang='scss'>
.share_container {
    padding: 30px;
}
</style>